import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/layout/components/home')
      },
      {
        path: 'operate',
        name: 'Operate',
        component: () => import('@/views/operate/index.vue')
      }
    ]
  }

]

const router = new VueRouter({
  routes
})

//设置路由守卫
router.beforeEach((to, from, next) => {
  next()
  /* must call `next` */
  if (to.path == '/login') {
    sessionStorage.removeItem("id");
  }
  let user = JSON.parse(sessionStorage.getItem("id"));
  if (!user && to.path != '/login') {
    next({ path: '/login' })
  } else {
    next();
  }
});

//解决路由重复跳转报错问题
const originaPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}

export default router
