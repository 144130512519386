import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'; // 时间处理

Vue.config.productionTip = false
Vue.prototype.$moment = moment;

Vue.use(ElementUI);

// 引入axios方法
import axios, {
  post,
  post1,
  get,
  postDown,
  postDown2,
  Delete,
  patch,
} from './untils/https.js';

Vue.prototype.$axios = axios;
Vue.prototype.$post = post;
Vue.prototype.$post1 = post1;
Vue.prototype.$get = get;
Vue.prototype.$postDown = postDown;
Vue.prototype.$postDown2 = postDown2;
Vue.prototype.$del = Delete;
Vue.prototype.$patch = patch;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
